import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import "./ShopList.css";
import { Button } from "@mui/material";

const ShopRentalList = (props) => {
  const { language, rentalProducts, handleRentalDialog } = props;
  const products =
    rentalProducts.items.filter((prod) => prod.type === "RENTAL") || [];

  const getPropertyMinPrice = (properties) => {
    return Math.max(properties.map((property) => property.price));
  };

  return (
    <div className="shop-list-body">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 1, md: 1 }}
          columns={{ xs: 1, sm: 8, md: 12 }}
        >
          {products.map((product, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height="220"
                  image={process.env.PUBLIC_URL.concat("/media/", product.img)}
                  alt={product.img}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {product.name && product.name[language]}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product.description && product.description[language]}
                  </Typography>
                  <Typography>
                    Alkaen{" "}
                    {product.properties &&
                      getPropertyMinPrice(product.properties)}{" "}
                    €
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    target="_blank"
                    href={`
                  mailto:asiakaspalvelu@meriteijobikepark.com?subject=Varusteiden vuokraus&body=Varusteiden vuokraus: ${product.name[language]}%0D%0A%0D%0A
                  Kuinka monta pyörää?:%0D%0A
                  Kuljettajien pituus:%0D%0A
                  Kuljettajien paino:%0D%0A
                  Vuokra-aika toive (esim. 19.8. klo 15-19):%0D%0A
                  Nimi:%0D%0A
                  Puhelinnumero:%0D%0A
                  Sähköposti:%0D%0A
                  Lisätiedustelut ja kysymykset (esim. useampi pyörä, valmennus,... ):
                  `}
                    color="inherit"
                  >
                    Tilaa sähköpostilla
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default ShopRentalList;
