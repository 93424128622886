import React, { useState } from "react";
import localization from "../localization/localization";
import ShopRentalList from "./ShopRentalList";
import ShopTicketListNew from "./ShopTicketList";
import ShopServiceList from "./ShopServiceList";
import ShopTrainingList from "./ShopTrainingList";
import RentalDialog from "./RentalDialog";
import "./ShopTabs.css";

const ShopTabs = (props) => {
  const [tab, setTab] = useState("rent");
  const [rentalDialog, setRentalDialog] = useState({ id: null, open: false });

  const handleRentalDialogOpen = (state) =>
    setRentalDialog({ ...rentalDialog, open: state });

  return (
    <div className="tabs-body">
      <div className="tabs-button-container">
      <button
          disabled={false}
          className={tab === "ticket" ? "active" : ""}
          onClick={() => setTab("ticket")}
        >
          {localization.shop.products.Tickets.title}
        </button>
        <button
          disabled={false}
          className={tab === "rent" ? "active" : ""}
          onClick={() => setTab("rent")}
        >
          {localization.shop.products.Rentals.title}
        </button>
        <button
          disabled={true}
          className={tab === "trainings" ? "active" : ""}
          onClick={() => setTab("trainings")}
        >
          {localization.shop.products.Trainings.title}
        </button>
        <button
          disabled={true}
          className={tab === "services" ? "active" : ""}
          onClick={() => setTab("services")}
        >
          {localization.shop.products.Services.title}
        </button>    
      </div>

      {tab === "ticket" && (
        <ShopTicketListNew
          onlineStore={props.onlineStore}
          addStoreItem={props.addStoreItem}
          rentalProducts={props.rentalProducts}
          removeStoreItem={props.removeStoreItem}
          addOnlineStoreItem={props.addOnlineStoreItem}
          language={props.language}
        />
      )}
      {tab === "rent" && (
        <ShopRentalList
          language={props.language}
          rentalProducts={props.rentalProducts}
          handleRentalDialog={setRentalDialog}
        />
      )}
      {tab === "services" && (
        <ShopServiceList
          onlineStore={props.onlineStore}
          addStoreItem={props.addStoreItem}
          removeStoreItem={props.removeStoreItem}
        />
      )}
      {tab === "trainings" && (
        <ShopTrainingList
          onlineStore={props.onlineStore}
          addStoreItem={props.addStoreItem}
          removeStoreItem={props.removeStoreItem}
        />
      )}
      {rentalDialog.open && (
        <RentalDialog
          language={props.language}
          rentalDialog={rentalDialog}
          addProductToBasket={props.addProductToBasket}
          rentalProducts={props.rentalProducts.items}
          handleClose={() => handleRentalDialogOpen(false)}
          setReservation={props.setReservation}
        />
      )}
    </div>
  );
};

export default ShopTabs;
